var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseUploadPopUp',{ref:"uploadPopUp",attrs:{"show":!!_vm.fileList.length,"cancel-text":_vm.$t('cancel'),"upload-text":{
    title: 'upload.title',
    upload: 'upload.upload',
    done: 'upload.done',
    retry: 'upload.retry',
    quotaExceeded: 'upload.quotaExceeded',
  },"current-status":_vm.currentStatus,"file-list":_vm.fileList,"rejected-files":_vm.rejectedFiles,"file-errors":_vm.fileErrors,"user-space":_vm.userSpace,"upload-percentage":_vm.uploadPercentage,"uploaded-files":_vm.uploadedFiles},on:{"cancelUpload":_vm.cancelUpload,"removeFile":_vm.removeFile,"startUpload":_vm.startUpload}},[_c('div',{key:"popup-text",staticClass:"popup-text"},[_c('BaseDropDown',{staticClass:"upload-dropdown",attrs:{"label":_vm.$t('upload.choose_license'),"options":_vm.licenses,"show-label":true,"header-background-color":'rgb(240, 240, 240)',"language":_vm.$i18n.locale,"is-disabled":!_vm.isInitial,"value-prop":"source"},model:{value:(_vm.license),callback:function ($$v) {_vm.license=$$v},expression:"license"}}),_c('BaseDropDown',{staticClass:"upload-dropdown",attrs:{"options":[
        { label: _vm.$t('no'), value: 'false' },
        { label: _vm.$t('yes'), value: 'true' }],"header-background-color":'rgb(240, 240, 240)',"label":_vm.$t('upload.publish_images'),"show-label":true,"is-disabled":!_vm.isInitial},model:{value:(_vm.publish),callback:function ($$v) {_vm.publish=$$v},expression:"publish"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }