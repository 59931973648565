var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"import-page"},[_c('BaseRow',{attrs:{"disable-save-button":!_vm.selectedRecords.length,"buttons":[{
                 action: 'importAll',
                 disabled: false,
                 icon: 'download',
                 text: 'import.importAll',
                 unsavedChanges: false,
                 visible: !!_vm.results.length && !!_vm.importedFileNames.length,
               },
               {
                 action: 'importSelected',
                 disabled: !_vm.selectedRecords.length,
                 icon: 'download',
                 text: 'import.importSelected',
                 unsavedChanges: !!_vm.selectedRecords.length,
                 visible: !!_vm.results.length,
               }],"save-button-text":'import.importSelected',"save-button-icon":'download',"show-search":_vm.primoImporter && !_vm.importedFileNames.length,"show-back-button":false,"show-mobile-back-button":!_vm.results.length,"show-remove-button":!!_vm.importedFileNames.length,"show-save-button":!!_vm.results.length,"title":!_vm.primoImporter ? _vm.$t('import.importBibTexTitle') : _vm.importedFileNames,"unsaved-changes":!!_vm.selectedRecords.length},on:{"return":_vm.cancelImport,"remove":_vm.resetSearch,"search":_vm.runSearch,"save":_vm.onImport}}),(_vm.dropboxVisible)?_c('label',[_c('BaseDropBox',{staticClass:"drop-box",attrs:{"box-size":{ width: 'calc(100%)', height: '250px'},"icon":'add-new-object',"text":_vm.$t('import.attachBibTex'),"subtext":_vm.$t('import.clickordrag')},on:{"dropped-file":function($event){return _vm.handleFileSelect($event)}}}),_c('input',{ref:"fileInput",staticClass:"hide",attrs:{"type":"file","accept":_vm.acceptedFileTypes,"multiple":""},on:{"click":_vm.resetInput,"change":_vm.handleFileSelect}})],1):_vm._e(),(!!_vm.filesToUpload.length)?_c('BibtexParser',{attrs:{"file-list":_vm.filesToUpload,"enable-publishing":false},on:{"cancel":_vm.resetFiles,"import-failed":function($event){return _vm.onBibtexImportFailed($event)},"success":function($event){return _vm.onBibtexFilesParsed($event)}}}):_vm._e(),_c('div',{staticClass:"results-container"},[(_vm.results && _vm.results.length && !_vm.isLoading)?_c('BaseSelectOptions',{attrs:{"list":_vm.currentPageRecords,"selected-list":_vm.selectedIds,"selected-number-text":_vm.$t(
        'entriesSelected',
        { type: _vm.$tc('notify.entry', _vm.selectedRecords.length) }
      ),"select-text":_vm.$t('selectAll'),"deselect-text":_vm.$t('selectNone'),"data-e2e-import-results-options":""},on:{"selected":_vm.selectAll},scopedSlots:_vm._u([{key:"selectedText",fn:function(){return [_vm._v(" "+_vm._s(((_vm.selectedRecords.length) + "/" + (_vm.results.length) + " " + (_vm.$t('entriesSelected', { type: _vm.$tc('entry', _vm.results.length) }))))+" ")]},proxy:true}],null,false,2267566148)}):_vm._e(),(_vm.isLoading)?_c('BaseLoader',{staticClass:"loader",attrs:{"data-e2e-import-loading":""}}):_vm._e(),(_vm.results && _vm.results.length && !_vm.isLoading)?_c('SelectableAccordion',{attrs:{"list":_vm.currentPageRecords,"selected-list":_vm.selectedIds,"expand-mode":'single',"data-e2e-import-results":""},on:{"selected":function($event){return _vm.selectRecord($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var item = ref.item;
return [_c('table',{staticStyle:{"width":"100%"}},[_c('tbody',[_c('tr',[_c('td',{staticClass:"term-column"},[_vm._v(" "+_vm._s(_vm.$t('import.title'))+" ")]),_c('td',{staticClass:"definition-column"},[_vm._v(" "+_vm._s(item.title)+" ")])]),(item.authors)?_c('tr',[_c('td',{staticClass:"term-column"},[_vm._v(" "+_vm._s(_vm.$t('import.contributor'))+" ")]),_c('td',{staticClass:"definition-column"},[_vm._v(" "+_vm._s(item.authors.toString())+" ")])]):_vm._e(),_c('tr',[_c('td',{staticClass:"term-column"},[_vm._v(" "+_vm._s(_vm.$t('import.year'))+" ")]),_c('td',{staticClass:"definition-column"},[_vm._v(" "+_vm._s(item.year)+" ")])])])])]}}],null,false,1282515107)}):_vm._e()],1),(_vm.noResultsText)?_c('div',{staticClass:"no-entries"},[_c('p',{staticClass:"no-entries-title"},[_vm._v(" "+_vm._s(_vm.$t('noMatchingEntriesTitle'))+" ")]),_c('p',{staticClass:"no-entries-subtext"},[_vm._v(" "+_vm._s(_vm.$t('noMatchingEntriesSubtext'))+" ")])]):_vm._e(),(_vm.results && _vm.results.length && !_vm.isLoading && _vm.pageCount > 1)?_c('BasePagination',{attrs:{"current":_vm.currentPage,"total":_vm.pageCount,"data-e2e-import-results-pagination":""},on:{"set-page":function($event){_vm.currentPage = $event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }